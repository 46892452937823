/** @format */

.footer-container {
  background-color: #ffffff;
  padding: 0px 0 0px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20vh;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

/*Social icons*/

.social-icon-link {
  color: #000000;
  font-size: 30px;
  transform: translateY(-5px);
}

.social-icon-link-email img {
  transform: translateY(-5px);
  width: 35px;
  height: 35px;
  margin-left: 0px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
}

.social-logo {
  color: #000000;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-family: "Raleway", "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: 500;
}

.social-logo img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  margin-top: 4px;
}

.website-rights {
  color: #000000;
  margin-bottom: 16px;
  font-family: "Raleway", "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: 600;
}

@media screen and (max-width: 840px) {
  .footer-container {
    background-color: #ffffff;
    padding: 0px 0 0px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20vh;
  }

  .social-logo {
    margin-left: auto;
    margin-right: auto;
  }

  .social-media-wrap {
    flex-direction: column;
  }

  .social-icon-link {
    transform: translateY(-5px);
    margin-left: -15px;
  }

  .social-icon-link-email img {
    transform: translateY(-5px);
    width: 35px;
    height: 35px;
  }

  .social-icons {
    margin-left: auto;
    margin-right: auto;
  }

  .website-rights {
    margin-right: auto;
    margin-left: auto;
  }
}
