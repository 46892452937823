/** @format */

.project-display {
  max-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.back-link > img.back-icon {
  width: 30px;
  height: 30px;
  margin-right: 0px;
  margin-left: 0px;
  box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.17);
}

.back-link {
  height: 40px;
  width: 100px;
  display: flex;
  align-items: center; /* Align items vertically within the flex container */
  justify-content: center;
  font-size: 30px;
  font-weight: lighter;
  margin-top: 20px;
  margin-bottom: 7px;
  margin-left: 20px;
  transition: filter 0.3s ease-in, transform 0.2s ease-out; /* Separate transitions */
  font-family: "Raleway", "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: #8e8e8e;
}

.back-link:hover {
  filter: grayscale(100%) brightness(0%);
  /* transition: filter 0.3s ease-out; Keep the hover transition separate */
  transform: scale(1.1); /* Added a slight scale on hover */
}

.project-display a {
  text-decoration: none;
}

.project-display h1 {
  font-size: 50px;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 100px;
  font-family: "Raleway", "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.project-display img {
  max-width: 60%;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 6px 20px 10px rgba(56, 125, 255, 0.17);
  border-radius: 10px;
  display: flex;
}

.project-display p {
  max-width: 90%;
  font-size: 25px;
  margin-left: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: "Raleway", "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: bold;
  text-align: justify;
  hyphens: auto;
}

.project-display p.proj-lang {
  font-size: 25px;
  font-weight: lighter;
}

.project-display a.link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #000;
  font-size: 20px;
  border: 2px solid #000;
  border-radius: 40px;
  padding: 5px 10px;
  margin-bottom: 20px;
  margin-left: 100px;
  font-family: "Raleway", "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: bolder;
}

.project-display a.link i {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  margin-top: 3px;
}

@media screen and (max-width: 1160px) {
  .project-display img {
    max-width: 70%;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Add the shadow */
  }
}

@media screen and (max-width: 850px) {
  .project-display h1 {
    font-size: 45px;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 0px;
  }

  .back-link {
    margin-left: -7px;
  }
  .project-display p {
    max-width: 100%;
    font-size: 25px;
    margin-left: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
    hyphens: auto;
  }

  .project-display a.link {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #000;
    font-size: 20px;
    border: 2px solid #000;
    border-radius: 40px;
    padding: 5px 10px;
    margin-bottom: 20px;
    margin-left: 0px;
  }

  .project-display a.link i {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    margin-top: 5px;
  }

  .project-display img {
    max-width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Add the shadow */
  }
}

@media screen and (max-width: 300px) {
  .project-display h1 {
    font-size: 50px;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: justify;
  }

  .project-display p {
    max-width: 90%;
    font-size: 25px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;

    text-align: justify;
  }

  .project-display a.link {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #000;
    font-size: 25px;
    border: 2px solid #000;
    border-radius: 40px;
    padding: 5px 10px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .project-display img {
    max-width: 60%;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Add the shadow */
  }
}
