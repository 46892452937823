/** @format */

:root {
  --primary-color: #000000;
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn,
.btn:active,
.btn:focus {
  outline: none;
}

.btn--primary {
  background-color: var(--primary-color);
  color: #242424;
  border: 1px solid var(--primary-color);
}

.btn--outline {
  background-color: transparent;
  color: #000000;
  padding: 8px 20px;
  border: 2px solid var(--primary-color);
  transition: all 0.3s ease-out;
}

.btn--text {
  background-color: transparent;
  color: #8e8e8e;
  padding: 8px 20px;
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 20px;
  font-family: "Raleway", "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: 700;
}

.btn--medium2 {
  padding: 8px 20px;
  font-size: 20px;
  font-family: "Raleway", "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: 700;
  outline: none;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--medium:hover,
.btn--large:hover {
  background: #c2c2c2;
  color: #242424;
  transition: 250ms;
  font-weight: bolder;
}

.btn--medium2:hover {
  background: #ffffff;
  color: #000000;
  transition: 250ms;
  font-weight: bolder;
  outline: none;
}
