/** @format */

.navbar {
  background: linear-gradient(
    90deg,
    rgb(255, 255, 255) 0%,
    rgb(255, 255, 255) 100%
  );
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  /* max-width: 2000px;  */
  width: 90%;
  /* margin: 0 auto; */
}

.navbar-logo {
  width: 300px;
  color: #000000;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  /* margin-left: 60px; */
  font-family: "Raleway", "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: 700;
  position: relative;
}

.navbar-logo img {
  width: 35px;
  height: 35px;
  margin-left: 5px;
  margin-top: 6px;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: 2rem;
  list-style: none;
  overflow-y: auto;
  max-height: calc(
    100vh - 80px
  ); /* Limit the menu height to the viewport height minus navbar height */
}

.nav-menu::before {
  content: ""; /* Create an empty pseudo-element for spacing */
  flex: 1; /* Fill the available space to push the items to the right */
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #000000;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-family: "Raleway", "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.nav-links:hover {
  border-bottom: 4px solid #000000;
  transition: all 0.2s ease-out;
  font-weight: bolder;
}

.fa-bars {
  color: #000000;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: inline;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #ffffff;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    font-family: "Raleway", "Trebuchet MS", "Lucida Sans Unicode",
      "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  }

  .nav-links:hover {
    background-color: #ffffff;
    color: #242424;
    border-radius: 0;
    font-weight: bolder;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(10%, 36%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #000000;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #000000;
    padding: 14px 20px;
    border: 2px solid #000000;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #c2c2c2;
    color: #242424;
    transition: 250ms;
  }
}
