/** @format */

body {
  overflow-y: scroll;
}

.main-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0.2);
  object-fit: contain;
  margin: 0 auto;
  padding-bottom: 138px;
}

.main-container > h1 {
  font-size: 80px;
  color: #000000;
  margin-top: 100px;
  font-family: "Raleway", "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.main-text-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  margin-top: 40px;
}

.main-text-container > p.name {
  font-size: 30px;
  color: #000000;
  font-family: "Raleway", "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  width: 270px;
}

.main-text-container > p.intro {
  font-size: 25px;
  color: #000000;
  margin-top: 20px;
  font-family: "Raleway", "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: 400;
}

@media screen and (max-width: 1160px) {
  .main-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0.2);
    object-fit: contain;
    margin: 0 auto;
  }

  .main-text-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    margin-top: 40px;
  }
}

@media screen and (max-width: 960px) {
  .main-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0.2);
    object-fit: contain;
    margin: 0 auto;
  }

  .main-container > h1 {
    font-size: 70px;
    margin-top: 100px;
  }

  .main-container > p.name {
    font-size: 30px;
    color: #000000;
    margin-top: 8px;
    font-family: "Raleway", "Trebuchet MS", "Lucida Sans Unicode",
      "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  }
}

@media screen and (max-width: 768px) {
  .main-container > h1 {
    font-size: 60px;
    margin-top: 100px;
  }

  .main-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .main-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0.2);
    object-fit: contain;
  }

  .main-container > h1 {
    margin-top: 30px;
  }

  .main-text-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    margin-top: 40px;
  }
}
